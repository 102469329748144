import React from "react";
import { useEffect, useState } from "react";
import { navigate } from "gatsby";
import gsap from "gsap";
import vinyl2 from "../images/anna-brear-vinylB-mock-up-cropped.png"

// The current position of mouse
let x = 0;
let y = 0;
const AudioPlayer = (props) => {
  const [streaming, setStreaming] = useState(true);

  function setPlaying(play) {
    setStreaming(play);

    /*
    if (play) {
      tl.play();
    } else tl.pause();

    */
  }

  useEffect(() => {}, []);

  return (
    <>
      <img
        class="scratch"
        src={vinyl2}
        //src="https://greensock.com/wp-content/uploads/custom/draggable/img/knob.png"
      ></img>
      <br />
      Now Playing: {props.artist} - {props.title}
    </>
  );
};

export default AudioPlayer;
