import React from "react";
import { useEffect, useState, useReducer } from "react";
import { navigate } from "gatsby";
import gsap from "gsap";
import AudioPlayer from "../components/AudioPlayer";

import { reducer } from "../helpers/Reducer";
import mworker from "../helpers/MusicWorker.js";

let tl = gsap.timeline({ repeat: -1 });

let tmp = null;
let audio = null;
let audioSource = null;
let audioDestination = null;
let analyser = null;
let connn = null;
let wakeLock = null;
let lastFrame = null;
let enabledd = false;
let audioCtx = null;

const baddie = {
  src: "https://realmtest.sfo3.digitaloceanspaces.com/BADDIE%201%20-%20Pour%20Up.mp3",
  title: "POUR UP",
  artist: "BADDIE",
  album: "SPACES",
  artwork: [
    {
      src: "https://realmtest.sfo3.digitaloceanspaces.com/rede.ico",
      sizes: "96x96",
      type: "image/x-icon",
    },
  ],
  style: {
    bg: "#000",
    fg: "#FFF",
  },
};

const cashincashout = {
  src: "https://realmtest.sfo3.digitaloceanspaces.com/cashincashout.mp3",
  title: "CASHINCASHOUT",
  artist: "Pharell",
  album: "SPACES",
  artwork: [
    {
      src: "https://realmtest.sfo3.digitaloceanspaces.com/rede.ico",
      sizes: "96x96",
      type: "image/x-icon",
    },
  ],
  style: {
    bg: "#F0F",
    fg: "#FFF",
  },
};

const dualipa = {
  src: "https://realmtest.sfo3.digitaloceanspaces.com/levitating.mp3",
  title: "Levitating",
  artist: "Dua Lipa ft. DaBaby",
  album: "SPACES",
  artwork: [
    {
      src: "https://realmtest.sfo3.digitaloceanspaces.com/rede.ico",
      sizes: "96x96",
      type: "image/x-icon",
    },
  ],
  style: {
    bg: "#F0F",
    fg: "#FFF",
  },
};

const uche0 = {
  src: "https://realmtest.sfo3.digitaloceanspaces.com/uche/%E2%80%9CHow%20U%20Bummin%E2%80%9D%20BloodHoundZMoney%20Ft.%20CEO%20Trayle.m4a",
  title: "How U bumming",
  artist: "CEO Trayle",
  album: "SPACES",
  artwork: [
    {
      src: "https://realmtest.sfo3.digitaloceanspaces.com/rede.ico",
      sizes: "96x96",
      type: "image/x-icon",
    },
  ],
  style: {
    bg: "#F0F",
    fg: "#FFF",
  },
};

const uche1 = {
  src: "https://realmtest.sfo3.digitaloceanspaces.com/uche/Mention%20Me%20__%20TrendyBeatz.com.mp3",
  title: "Mention Me",
  artist: "Drake",
  album: "SPACES",
  artwork: [
    {
      src: "https://realmtest.sfo3.digitaloceanspaces.com/rede.ico",
      sizes: "96x96",
      type: "image/x-icon",
    },
  ],
  style: {
    bg: "#F0F",
    fg: "#FFF",
  },
};

const playlist = [uche0, uche1];
let index = 0;

const Scratch = (props) => {
  const [streaming, setStreaming] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [selected, setSelected] = useState(playlist[index]);

  function animm(shouldAnim) {
    try {
      const container = document.getElementById("container");
      const canvas = document.getElementById("canvas");
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      const ctx = canvas.getContext("2d");

      audio = audio || document.getElementById("aud");

      if (enabledd) {
        tmp = tmp || new Audio();
        tmp.crossOrigin = "anonymous";
        tmp.src = playlist[index].src;

        audioCtx =
          audioCtx || new (window.AudioContext || window.webkitAudioContext)();

        audioSource = audioSource || audioCtx.createMediaElementSource(tmp); // creates an audio node from the audio source

        audioDestination =
          audioDestination || audioCtx.createMediaStreamDestination();

        analyser = analyser || audioCtx.createAnalyser();
        if (!connn) {
          // creates an audio node for analysing the audio data for time and frequency
          audioSource.connect(analyser); // connects the audio source to the analyser. Now this analyser can explore and analyse the audio data for time and frequency
          analyser.connect(audioDestination); // connects the analyser to the destination. This is the speakers
          analyser.fftSize = 128; // controls the size of the FFT. The FFT is a fast fourier transform. Basically the number of sound samples. Will be used to draw bars in the canvas
          connn = true;
        }
        const bufferLength = analyser.frequencyBinCount; // the number of data values that dictate the number of bars in the canvas. Always exactly one half of the fft size
        const dataArray = new Uint8Array(bufferLength); // coverting to unsigned 8-bit integer array format because that's the format we need

        const barWidth = canvas.width / 2 / bufferLength; // the width of each bar in the canvas

        let x = 0; // used to draw the bars one after another. This will get increased by the width of one bar

        function animate() {
          if (enabledd) {
            //console.log("ping");
            x = 0;
            ctx.clearRect(0, 0, canvas.width, canvas.height); // clears the canvas
            analyser.getByteFrequencyData(dataArray); // copies the frequency data into the dataArray in place. Each item contains a number between 0 and 255
            drawVisualizer({ bufferLength, dataArray, barWidth });
            lastFrame = requestAnimationFrame(animate); // calls the animate function again. This method is built in
          } else cancelAnimationFrame(lastFrame);
        }

        const drawVisualizer = ({ bufferLength, dataArray, barWidth }) => {
          let barHeight;
          for (let i = 0; i < bufferLength; i++) {
            barHeight = dataArray[i]; // the height of the bar is the dataArray value. Larger sounds will have a higher value and produce a taller bar
            const red = (i * barHeight) / 10;
            const green = i * 4;
            const blue = barHeight / 4 - 12;
            ctx.fillStyle = `rgb(${red}, ${green}, ${blue})`;
            ctx.fillRect(
              canvas.width / 2 - x, // this will start the bars at the center of the canvas and move from right to left
              canvas.height - barHeight,
              barWidth,
              barHeight
            ); // draws the bar. the reason we're calculating Y weird here is because the canvas starts at the top left corner. So we need to start at the bottom left corner and draw the bars from there
            x += barWidth; // increases the x value by the width of the bar
          }

          for (let i = 0; i < bufferLength; i++) {
            barHeight = dataArray[i]; // the height of the bar is the dataArray value. Larger sounds will have a higher value and produce a taller bar
            const red = (i * barHeight) / 10;
            const green = i * 4;
            const blue = barHeight / 4 - 12;
            ctx.fillStyle = `rgb(${red}, ${green}, ${blue})`;
            ctx.fillRect(x, canvas.height - barHeight, barWidth, barHeight); // this will continue moving from left to right
            x += barWidth; // increases the x value by the width of the bar
          }
        };

        animate();
      } else {
        if (connn) {
          analyser.disconnect(audioDestination);
          audioSource.disconnect(analyser);

          connn = false;
        }
      }

      if (tmp) {
        tmp.currentTime = audio.currentTime;

        //(!audio.paused)
        if (shouldAnim)
          tmp.play().then(() => {
            if ("mediaSession" in navigator) {
              navigator.mediaSession.metadata = new MediaMetadata({
                title: playlist[index].title,
                artist: playlist[index].artist,
                album: playlist[index].album,
                artwork: playlist[index].artwork,
              });

              navigator.mediaSession.setActionHandler("play", function () {
                setPlaying(true, playlist[index]);
              });
              navigator.mediaSession.setActionHandler("pause", function () {
                setPlaying(false, playlist[index]);
              });

              navigator.mediaSession.setActionHandler(
                "previoustrack",
                function () {
                  index = (index - 1 + playlist.length) % playlist.length;
                  setPlaying(true, playlist[index]);
                }
              );

              navigator.mediaSession.setActionHandler("nexttrack", function () {
                index = (index + 1) % playlist.length;
                setPlaying(true, playlist[index]);
              });

              navigator.mediaSession.setActionHandler("seekto", function (e) {
                audio.currentTime = e.seekTime;
                if (tmp) tmp.currentTime = e.seekTime;
              });
            }
          });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function setPlaying(play, current) {
    setStreaming(play);
    if (tmp) tmp.pause();

    audio = audio || document.getElementById("aud");

    audio.crossOrigin = "anonymous";

    animm(play);
    if (play) {
      try {
        wakeLock = await navigator.wakeLock.request("screen");
      } catch (err) {}

      if (current.src !== audio.src) {
        audio.src = current.src;
      }

      /* visual bg for now
      gsap.to(document.body, {
        background: current.style.bg,
        color: current.style.fg,
        duration: 3,
      });
      */

      audio.onended = function () {
        index = (index + 1) % playlist.length;
        setPlaying(true, playlist[index]);
      };

      audio.addEventListener("play", function () {
        navigator.mediaSession.playbackState = "playing";
      });

      audio.addEventListener("pause", function () {
        navigator.mediaSession.playbackState = "paused";
      });

      audio.crossOrigin = "anonymous";
      audio
        .play()
        .then((e) => {})
        .catch((e) => {
          console.log(e);
        });
      tl.play();

      /*
      const worker = new window.Worker(mworker);

      worker.postMessage({});
      worker.onerror = (err) => err;
      worker.onmessage = (e) => {
        const { time } = e.data;
        alert(time);
      };
      */

      if ("mediaSession" in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: current.title,
          artist: current.artist,
          album: current.album,
          artwork: current.artwork,
        });

        navigator.mediaSession.setActionHandler("play", function () {
          setPlaying(true, playlist[index]);
        });
        navigator.mediaSession.setActionHandler("pause", function () {
          setPlaying(false, playlist[index]);
        });

        navigator.mediaSession.setActionHandler("previoustrack", function () {
          index = (index - 1 + playlist.length) % playlist.length;
          setPlaying(true, playlist[index]);
        });

        navigator.mediaSession.setActionHandler("nexttrack", function () {
          index = (index + 1) % playlist.length;
          setPlaying(true, playlist[index]);
        });

        navigator.mediaSession.setActionHandler("seekto", function (e) {
          audio.currentTime = e.seekTime;
          if (tmp) tmp.currentTime = e.seekTime;
        });
/*
        if ("setPositionState" in navigator.mediaSession) {
          navigator.mediaSession.setPositionState({
            duration: audio.duration,
            playbackRate: audio.playbackRate,
            position: audio.currentTime,
          });
          
        }
        */
      }
    } else {
      audio.pause();
      tl.pause();
      /* visual bg for now
      gsap.to(document.body, {
        background: "#FFF",
        color: "#000",
        duration: 3,
      });
      */
      try {
        wakeLock.release().then(() => {
          wakeLock = null;
        });
      } catch (err) {}
    }

    setSelected(current);

    //var t = setTimeout(animm, 500);
  }

  useEffect(() => {
    tl.to(".scratch", {
      rotation: 360,
      ease: "linear",
      duration: 1,
    });

    tl.progress(0).pause();


  }, [tl]);

  return (
    <>

      <AudioPlayer {...selected} />
      <br />
      <button
        style={{
          background: "hsl(100deg 100% 32%)",
        }}
        class="pushable"
        id="submit"
        onClick={() => {
          index = (index - 1 + playlist.length) % playlist.length;
          setPlaying(true, playlist[index]);
        }}
      >
        <span
          style={{
            backgroundColor: "#0F0",
            color: "#000",
          }}
          class="front"
        >
          Prev
        </span>
      </button>

      <button
        style={{
          background: "hsl(100deg 100% 32%)",
        }}
        class="pushable"
        id="submit"
        onClick={() => {
          index = (index + 1) % playlist.length;
          setPlaying(true, playlist[index]);
        }}
      >
        <span
          style={{
            backgroundColor: "#0F0",
            color: "#000",
          }}
          class="front"
        >
          Next
        </span>
      </button>
      <br />
      <button
        style={{
          background: streaming
            ? "hsl(340deg 100% 32%)"
            : "hsl(100deg 100% 32%)",
        }}
        class="pushable"
        id="submit"
        onClick={() => setPlaying(!streaming, selected)}
      >
        <span
          style={{
            backgroundColor: streaming ? "#cc264a" : "#0F0",
            color: streaming ? "#FFF" : "#000",
          }}
          class="front"
        >
          {streaming ? "Pause" : "Play"}
        </span>
      </button>
      <br />

      <div id="container">
        <canvas id="canvas"></canvas>
      </div>

      <button
        style={{
          background: "hsl(340deg 100% 32%)",
        }}
        class="pushable"
        id="submit"
        onClick={() => {
          enabledd = !enabledd;

          setPlaying(streaming, selected);
          //setEnabled(!enabled);

          animm(streaming);
        }}
      >
        <span
          style={{
            backgroundColor: "#cc264a",
            color: "#000",
          }}
          class="front"
        >
          Visualizer
        </span>
      </button>

      <audio id="aud" controls={false}></audio>
    </>
  );
};

export default Scratch;
