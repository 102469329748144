import React, { Component, useState, useEffect } from "react"
import './index.css';
import './App.css';
import * as styles from './Home.module.css'
import { Helmet } from 'react-helmet'
import Scratch from "../components/Scratch";
import vinyl from "../images/Gerald_G_45_RPM_Record.svg"
import gsap from "gsap"

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export default class App extends Component {
  constructor() {
    super();

    this.state = {
      bgcolor: "#000",
      hidden: false
    }

    this.showDates = [
      "Loading ...",
    ]

  }
  componentDidMount() {
    this.setState({ bgcolor: "#FFF" })



    gsap.to("#block2", {
      opacity: 1,
      duration: 2,
      clipPath: 'circle(0%)'
    });
  }

  logoClick() {

    this.setState({ hidden: !this.state.hidden })
  }

  render() {
    const style = {
      backgroundColor: this.state.hidden ? "#000" : this.state.bgcolor,
      backgroundBlendMode: this.state.hidden ? "color-dodge" : "normal",
    }

    return (
      <><Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@900&display=swap" rel="stylesheet" />
        <link rel="apple-touch-icon" sizes="180x180" href="../images/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="../images/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="../images/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="../images/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="theme-color" content="#ffffff" />
        <title>TMP | PUNCH RADIO</title>
      </Helmet>
        <div
          id="block2"
          style={{
            position: 'absolute',
            width: "100%",
            height: "100%",
            backgroundColor: "#A00",
            color: "#FFF",
            zIndex: 10,
            clipPath: 'circle(100%)'

          }}><center>
            <div

              style={{
                position: 'absolute',
                width: "100%",
                height: "100%",
                marginTop: "35vh",
              }}
            >
              <h3>welcome to</h3>
              <h1>PUNCH RADIO</h1>
              <h4>Explore new sounds today.</h4></div>
          </center>

        </div>


      <div id="container">
        <canvas id="canvas"></canvas>
      </div>

      <audio id="aud" src="https://realmtest.sfo3.digitaloceanspaces.com/cashincashout.mp3" controls={true}></audio>

      </>
    )
  }
}
